/* style */

.wrapper {
   display: flex;
   flex-direction: row;
   justify-content: center;
   height: 100vh;
   width: 100vw;
}

.sideSection {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   height: 100%;
   min-width: 300px;
   flex-grow: 1;
   width: 100%;
}

.sideSection .inner {
   display: flex;
   flex-direction: column;
   gap: 16px;
   padding: 32px;
   max-width: 600px;
   margin-left: auto;
   margin-right: auto;
   height: 100%;
   justify-content: space-evenly;
}
.sideSection .logo {
   display: flex;
   flex-direction: column;
   gap: 16px;
   margin-bottom: 16px;
}

.sideSection h1 {
   font-size: 40px;
   font-weight: 500;
   color: white;
}

.sideSection p {
   font-size: 18px;
   font-weight: 300;
   color: white;
}

.formContainer {
   padding: 5%;
   align-self: center;
   max-width: 800px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 24px;
   min-width: 300px;
   width: 100%;
   max-width: 600px;
}
.formHeader {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 12px;
}
.formHeader .switchForm {
   cursor: pointer;
   color: blue;
   text-align: center;
}
.formContainer form {
   align-self: center;
   width: 100%;
}
.formContainer h1 {
   font-size: 32px;
   font-weight: 700;
   color: #333;
   text-align: center;
}

.formContainer form {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 24px;
}

.review {
   display: flex !important;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   gap: 12px;
   height: 300px;
   max-width: 500px;
}
.review h4 {
   font-size: 18px;
   font-weight: 400;
   color: white;
}

.clientInfo {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   gap: 12px;
}
.clientInfo img {
   width: 45px;
   height: 45px;
   border-radius: 50%;
   border: 2px solid white;
}
.review h5 {
   font-size: 16px;
   font-weight: 500;
   color: white;
}
.review p {
   font-size: 14px;
   font-weight: 300;
   color: white;
}

.forgotPassword {
   cursor: pointer;
   text-align: center;
   width: 100%;
   text-align: left;
}

.googleButtonInner {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
}

.googleButtonInner img {
   width: 18px;
   height: 18px;
   display: flex;
}
