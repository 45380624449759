.container {
   background: radial-gradient(at 18% 99%, #101840 0px, transparent 50%) repeat scroll 0% 0%,
      radial-gradient(at 97% 8%, #101840 0px, transparent 50%) repeat scroll 0% 0%,
      radial-gradient(at 109% 102%, #52bd95 0px, transparent 50%) repeat scroll 0% 0%,
      radial-gradient(at 96% 10%, #101840 0px, transparent 50%) repeat scroll 0% 0%,
      radial-gradient(at 42% 20%, #101840 0px, transparent 50%) repeat scroll 0% 0%,
      radial-gradient(at 4% 49%, #2952cc 0px, transparent 50%) repeat scroll 0% 0%,
      #101840 radial-gradient(at 57% 33%, #101840 0px, #2952cc 50%) repeat scroll 0% 0%;
   background-size: 160% 160%;
   animation: gradient 15s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   width: 100%;
   height: 100%;
   position: relative;
}

@keyframes gradient {
   0% {
      background-position: 0% 100%;
   }

   25% {
      background-position: 50% 0%;
   }

   50% {
      background-position: 100% 0%;
   }

   75% {
      background-position: 50% 100%;
   }
   100% {
      background-position: 0% 100%;
   }
} ;
